<template lang="pug">
  section#about.site-section
    div.container
      div.row.align-items-center
        div.col-lg-6.mb-5.mb-lg-0
          a(href="https://waynecommand.com").block__96788
            img(src="https://i.v2ex.co/ZGP5N5sw.jpeg" alt="Image").img-fluid.img-shadow
        div.col-lg-5.ml-auto
          h3.section-title-sub.text-primary About Wayne
          h2.section-title.mb-3 WayneCommand
          p.lead 这里是 Wayne, WayneCommand 的创建者，很高兴遇见你。
          p have a nice day.

</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.section-title {
  text-transform: none;
}

</style>