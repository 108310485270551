<template lang="pug">
  div#app
    div.site-wrap
      About
</template>

<script>
import '@/assets/css/style.css';
import '@/assets/css/custom-bs.css';
import About from "@/components/About";

export default {
  name: 'App',
  components: {
    About
  }
}
</script>

<style>
</style>
